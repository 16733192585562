import { Controller } from "stimulus";
import lottie from "lottie-web";

import about from '../animations/about.json';
import apple from '../animations/apple.json';
import arrows from '../animations/arrows.json';
import doctor from '../animations/doctor.json';
import home from '../animations/home.json';
import homeMobile from '../animations/home-mobile.json';
import logo from '../animations/logo.json';
import programmes from '../animations/programmes.json';

window.loadedAnimations = []

export default class extends Controller {
  connect() {
    this.setAnimations();

    this.params = {
      container: this.element,
      renderer: this.data.get("type"),
      loop: true,
      autoplay: true,
      animationData: this.getAnimationData(this.data.get("name")),
      rendererSettings: {
        preserveAspectRatio: 'xMinYMin meet',
        viewBoxOnly: true,
        className: this.data.get('className')
      }
    };

    if (!window.loadedAnimations.includes(this.data.get("name"))) {
      window.loadedAnimations.push(this.data.get("name"));

      lottie.loadAnimation(this.params);
    }
  }

  setAnimations() {
    this.about = about;
    this.apple = apple;
    this.arrows = arrows;
    this.doctor = doctor;
    this.home = home;
    this.homeMobile = homeMobile;
    this.logo = logo;
    this.programmes = programmes;
  }

  getAnimationData(name) {
    return this[name];
  }
}
