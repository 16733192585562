var scrolloverflow = require("fullpage.js/vendors/scrolloverflow.js");
var fullpage = require("fullpage.js");

var next = document.getElementById("next");

var fullPageInstance = new fullpage("#fullpage", {
  fixedElements: "#header",
  licenseKey: "5E442764-8421414C-98BF688F-F1A77A7A",
  paddingTop: "102px",
  responsiveWidth: 600,
  scrollOverflow: true
});
